import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { apiBase } from "../Config";
import CardForm from "../components/CardForm";
import { Modal, Form, Button, Row, Col, Spinner } from "react-bootstrap";

const stripePromise = loadStripe(
  "pk_live_51IBKtGC7FxMC3n4vimf9s7YT4uZU8plqGBord2VUS7AtT2D75noDsFieCPocMz0HJKofyZWgwkHT44F7cZCZkiWq00hNTux3IB"
);

class BillingMigration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReEnterCard: false,
      showScoutIQ: false,
      isScoutIQLogin: false,
      scoutIQEmail: "",
      scoutIQPassword: "",
      scoutIQLoginError: "",
      isSuccess: false,
      validatingToken: true,
      invalidToken: false,
      adminData: {},
    };
    this.scoutIQLogin = this.scoutIQLogin.bind(this);
    this.validateInvitation = this.validateInvitation.bind(this);
  }

  componentDidMount() {
    this.validateInvitation();
  }

  validateInvitation = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let appToken = params.get("token");

    // Verify Token
    this.setState({ validatingToken: true });
    const response = await fetch(`${apiBase}admin/validate-token?id=${appToken}`);
    const responseJson = await response.json();
    console.log(`Validating JSON. Response: `, responseJson);

    if (response.status === 200) this.setState({ adminData: responseJson.data });
    else this.setState({ invalidToken: true });

    this.setState({ validatingToken: false });
  };

  scoutIQLogin = async () => {
    this.setState({ scoutIQLoginError: "", isScoutIQLogin: true });
    const response = await fetch(apiBase + "admin/payments/get-scoutiq", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.adminData.admin_id,
        app_token: this.state.adminData.token,
        email: this.state.scoutIQEmail,
        password: this.state.scoutIQPassword,
      }),
    });
    this.setState({ isScoutIQLogin: false });
    if (response.status === 200) {
      const data = await response.json();
      this.setState({ isSuccess: true });
    } else this.setState({ scoutIQLoginError: "Error: INVALID LOGIN. TRY AGAIN!" });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.validatingToken ? (
          <div style={{ marginTop: "100px", textAlign: "center" }}>
            <Spinner animation="border" role="status" style={{ width: "150px", height: "150px" }}></Spinner>
            <div style={{ marginTop: "40px" }}>Fetching Details. Please Wait ...</div>
          </div>
        ) : this.state.invalidToken ? (
          <div style={{ marginTop: "100px", textAlign: "center", color: "red" }}>
            Invalid Request. Please contact <a href="mailto:support@palletiq.com">support@palletiq.com</a>
          </div>
        ) : (
          <div>
            <Form
              onSubmit={this.props.onFormSubmitAddMPBatch}
              style={{ textAlign: "center", width: "65%", margin: "0 auto" }}
            >
              <Modal.Header style={{ justifyContent: "center" }}>
                <h4 style={{ textAlign: "center" }}>Hi {this.state.adminData.first_name}! Important Billing Update</h4>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <div>
                  <p>We're in process of merging billing for PalletIQ with ScoutIQ.</p>
                  <p>Do you have an active ScoutIQ membership?</p>
                  <p>
                    <Button
                      variant="success"
                      onClick={() => this.setState({ showScoutIQ: true, showReEnterCard: false })}
                    >
                      YES
                    </Button>
                    <Button
                      variant="secondary"
                      style={{ marginLeft: "20px" }}
                      onClick={() => this.setState({ showScoutIQ: false, showReEnterCard: true })}
                    >
                      NO
                    </Button>
                  </p>
                </div>

                {this.state.showReEnterCard && (
                  <div style={{ marginTop: "30px", borderTop: "1px solid #ddd", paddingTop: "10px" }}>
                    <div>Please re-enter your card details to proceed</div>

                    <div style={{ marginTop: "15px" }}>
                      <Elements stripe={stripePromise}>
                        <CardForm
                          updateCardErrorMessage={this.updateCardErrorMessage}
                          isAddingCard={this.state.isAddingCard}
                          admin_data={this.state.admin_data}
                          updateAdminData={this.updateAdminData}
                        />
                      </Elements>
                    </div>
                  </div>
                )}

                {this.state.showScoutIQ && (
                  <div style={{ marginTop: "30px", borderTop: "1px solid #ddd", paddingTop: "10px" }}>
                    <div>Please login with your ScoutIQ team admin account below</div>
                    <Row style={{ marginTop: "10px" }}>
                      <Col md={6}>
                        <Form.Group controlId="formMWSSeller">
                          <Form.Label>ScoutIQ Email</Form.Label>
                          <Form.Control
                            value={this.state.scoutIQEmail}
                            onChange={(e) => this.setState({ scoutIQEmail: e.target.value })}
                            type="email"
                            name="mwsSellerId"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formMWSSeller">
                          <Form.Label>ScoutIQ Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="mwsSellerId"
                            value={this.state.scoutIQPassword}
                            onChange={(e) => this.setState({ scoutIQPassword: e.target.value })}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <div>
                      <Button variant="primary" onClick={() => this.scoutIQLogin()} style={{ width: "300px" }}>
                        {this.state.isScoutIQLogin === true ? (
                          <Spinner animation="border" role="status" style={{ width: "15px", height: "15px" }}>
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          " Login & Merge Billing"
                        )}
                      </Button>

                      {this.state.scoutIQLoginError && (
                        <div style={{ color: "red", marginTop: "20px" }}>{this.state.scoutIQLoginError}</div>
                      )}

                      {this.state.isSuccess && (
                        <div style={{ color: "green", marginLeft: "20px" }}>Migration Complete. Thanks!</div>
                      )}
                    </div>
                  </div>
                )}
              </Modal.Body>
            </Form>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default BillingMigration;
