/**
 * Helper Class; contains functions mostly associated with API calls.
 */

import { apiBase } from "../Config";
import API from "./Api";

//import { updateMaxProfit } from "../../../backend/includes/helperUser";

function addZeroes(amount) {
  if (amount === "N/A" || amount === undefined) return amount;
  else {
    // Cast as number
    let num = Number(amount);
    // If not a number, return 0
    if (isNaN(num)) {
      return 0;
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2) {
      num = num.toFixed(2);
    }
    // Return the number
    return num;
  }
}

export const getUSFormattedDate = (dateString) => {
  var date = new Date(dateString);
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "-" + day + "-" + year;
};

// Extract infoboxes prices from the API output
export const getInfoBoxesPrices = (asinData, isMM) => {
  let infoBoxes = [];

  // mm_index
  // const mmIndex = asinData.prices.vendors.findIndex((vIndex) => vIndex.vendor === "MP");

  // if ("fba_trigger_selected" in asinData.winner.amazonLogData && isMM === 1 && mmIndex === -1) {
  //   infoBoxes.push({
  //     name: "MP",
  //     price: 0,
  //     isAccepted: false,
  //   });
  // }
  if (
    "fba_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON")
  ) {
    // Amazon FBA
    const price =
      "estimated_profit_fba" in asinData.winner.amazonLogData ? asinData.winner.amazonLogData.estimated_profit_fba : 0;
    const isAccepted =
      "fba_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.fba_decision === 1
        ? true
        : false;
    infoBoxes.push({
      name: "FBA",
      price: price,
      isAccepted: isAccepted,
    });
  }

  if (
    "fba_ca_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON CA")
  ) {
    // Amazon FBA
    const price =
      "mm_ca_val" in asinData.winner.amazonLogData
        ? asinData.winner.amazonLogData.mm_ca_val
        : "estimated_profit_ca_fba" in asinData.winner.amazonLogData
        ? asinData.winner.amazonLogData.estimated_profit_ca_fba
        : 0;
    const isAccepted =
      "fba_ca_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.fba_ca_decision === 1
        ? true
        : false;
    infoBoxes.push({
      name: "FBA-CA",
      price: price,
      isAccepted: isAccepted,
    });
  }
  // if ("estimated_profit_fba" in asinData.winner.amazonLogData) {
  // }
  // else {
  //   infoBoxes.push({
  //     name: "FBA",
  //     price: 0,
  //     isAccepted: false,
  //   });
  // }
  // Amazon MF

  if (
    "mf_ca_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON CA")
  ) {
    const price =
      "estimated_profit_ca_mf" in asinData.winner.amazonLogData
        ? asinData.winner.amazonLogData.estimated_profit_ca_mf
        : 0;
    const isAccepted =
      "mf_ca_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.mf_ca_decision === 1
        ? true
        : false;

    infoBoxes.push({
      name: "MF-CA",
      price: price,
      isAccepted: isAccepted,
    });
  }

  if (
    "mf_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON")
  ) {
    const price =
      "estimated_profit_mf" in asinData.winner.amazonLogData ? asinData.winner.amazonLogData.estimated_profit_mf : 0;
    const isAccepted =
      "mf_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.mf_decision === 1 ? true : false;

    infoBoxes.push({
      name: "MF",
      price: price,
      isAccepted: isAccepted,
    });
  }

  if (
    "fba_new_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON NEW")
  ) {
    const price =
      "estimated_profit_fba_new" in asinData.winner.amazonLogData
        ? asinData.winner.amazonLogData.estimated_profit_fba_new
        : 0;
    const isAccepted =
      "fba_new_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.fba_new_decision === 1
        ? true
        : false;

    infoBoxes.push({
      name: "FBA-NEW",
      price: price,
      isAccepted: isAccepted,
    });
  }

  if (
    "mf_new_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON NEW")
  ) {
    const price =
      "estimated_profit_mf_new" in asinData.winner.amazonLogData
        ? asinData.winner.amazonLogData.estimated_profit_mf_new
        : 0;
    const isAccepted =
      "mf_new_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.mf_new_decision === 1
        ? true
        : false;

    infoBoxes.push({
      name: "MF-NEW",
      price: price,
      isAccepted: isAccepted,
    });
  }

  // WholeSellers

  const sbIndex = asinData.prices.vendors.findIndex((ven) => ven.vendor === "SB");
  const rmsIndex = asinData.prices.vendors.findIndex((ven) => ven.vendor === "RMS");

  asinData.prices.vendors.forEach((v) => {
    if (v.vendor === "RMS" || v.vendor === "SB") return;
    else if (
      v.vendor === "MP" &&
      (asinData.winner.winnerModule === "" || asinData.winner.amazonLogData.is_match_mp === 0)
    )
      infoBoxes.push({
        name: "MP",
        price: 0,
        isAccepted: false,
      });
    else if (
      v.vendor === "MP MF" &&
      (asinData.winner.winnerModule === "" || asinData.winner.amazonLogData.is_match_mp_mf === 0)
    )
      infoBoxes.push({
        name: "MP MF",
        price: 0,
        isAccepted: false,
      });
    else if (
      v.vendor === "MP-CA" &&
      (asinData.winner.winnerModule === "" || asinData.winner.amazonLogData.is_match_mp === 0)
    )
      infoBoxes.push({
        name: "MP-CA",
        price: 0,
        isAccepted: false,
      });
    else
      infoBoxes.push({
        name: v.vendor === "BOOKSCOUTER" ? "BS" : v.vendor,
        price: v.price,
        isAccepted: v.price > 0 ? true : false,
      });
  });

  // This will bring RMS to the right of SBYB
  const sbybIndex = infoBoxes.findIndex((ib) => ib.name === "SBYB");
  if (sbybIndex >= 0) {
    infoBoxes.push(infoBoxes.splice(sbybIndex, 1)[0]);
  }
  if (sbIndex >= 0 || rmsIndex >= 0) {
    let sbPrice = 0,
      rmsPrice = 0;

    sbPrice = sbIndex >= 0 ? asinData.prices.vendors[sbIndex].price : 0;
    rmsPrice = rmsIndex >= 0 ? asinData.prices.vendors[rmsIndex].price : 0;
    const higher_price = Math.max(sbPrice, rmsPrice);

    infoBoxes.push({
      name: "RMS",
      price: higher_price,
      isAccepted: higher_price > 0 ? true : false,
    });
  }

  // This will bring UBB to the end
  const ubbIndex = infoBoxes.findIndex((ib) => ib.name === "UBB");
  if (ubbIndex >= 0) {
    infoBoxes.push(infoBoxes.splice(ubbIndex, 1)[0]);
  }

  return infoBoxes;
};

export const addToZiffitCart = (idType, searchText, asin, userId, token) => {
  fetch(apiBase + "user/add-to-ziffit", {
    method: "POST",
    body: new URLSearchParams({
      user_id: userId,
      app_token: token,
      asin: idType === "ISBN" || idType === "UPC" ? searchText : asin,
    }),
  }).catch((error) => {
    console.log(`Error calling ZIFFIT add to cart`, error);
  });
};

export const updateZiffitCart = async (cartId, asin, quantity) => {
  try {
    const response = await fetch(
      apiBase + `user/update-ziffit/?cart_id=${cartId}&asin=${asin}&quant=${quantity >= 1 ? quantity : -1}`,
      {
        method: "GET",
        header: {
          "Accept-Language": "en-US",
          "X-Region-Id": "US",
        },
      }
    );
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const addNewMPSource = async (sourceName, sourcePalletValue, userId, token) => {
  try {
    const response = await fetch(apiBase + "sources/", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        name: sourceName,
        pallet_value: sourcePalletValue,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const overrideLP = async (userId, token, scanId, commandKey, commandVal) => {
  try {
    const response = await fetch(apiBase + "isbn/overridelp/" + scanId, {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        override_key: commandKey,
        override_val: commandVal,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const overrideShipment = async (userId, token, scanId, shipmentName) => {
  try {
    const response = await fetch(apiBase + "isbn/overrideshipment", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        scan_id: scanId,
        shipment_name: shipmentName,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const overrideSource = async (userId, token, newSource) => {
  try {
    const response = await fetch(apiBase + "user/update-source", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        new_source: newSource,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const deleteScanLog = async (scanId, userId, token) => {
  try {
    const response = await fetch(apiBase + "user/delete-scan", {
      method: "POST",
      body: new URLSearchParams({
        scan_id: scanId,
        user_id: userId,
        app_token: token,
      }),
    });
    const apiResponse = await response.json();
    return apiResponse;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const deleteMPScanLog = async (scanId, userId, token) => {
  try {
    const response = await fetch(apiBase + "user/delete-mp-scan", {
      method: "POST",
      body: new URLSearchParams({
        scan_id: scanId,
        user_id: userId,
        app_token: token,
      }),
    });
    const apiResponse = await response.json();
    return apiResponse;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const toggleMP = async (userId, token) => {
  try {
    const response = await fetch(apiBase + "marketplace/toggle", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
      }),
    });
    const apiResponse = await response.json();
    return apiResponse;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const formatAsinData = (asinData) => {
  asinData.formatted = {};
  if ("status" in asinData && asinData.status === "success") {
    // Format Salesrank to "K" (Thousands) & "M" (Millions)
    asinData.formatted.salesrank =
      asinData.meta.sales_rank > 0 && asinData.meta.sales_rank <= 999
        ? asinData.meta.sales_rank
        : asinData.meta.sales_rank > 999 && asinData.meta.sales_rank < 999999
        ? Math.round(asinData.meta.sales_rank / 1000) + "k"
        : asinData.meta.sales_rank > 999999
        ? (asinData.meta.sales_rank / 1000000).toFixed(1) + "M"
        : "N/A";

    // Format Title
    asinData.formatted.title = "title" in asinData.meta && asinData.meta.title !== "" ? asinData.meta.title : "N/A";

    // Format Trade-In Price
    asinData.formatted.tradeIn =
      asinData.prices.trade_in && parseFloat(asinData.prices.trade_in) > 0 ? asinData.prices.trade_in : 0;

    // Format Amazon Price
    asinData.formatted.amazonPrice =
      "amazon_price" in asinData.prices && parseFloat(asinData.prices.amazon_price) > 0
        ? "$" + parseFloat(asinData.prices.amazon_price).toFixed(2)
        : "N/A";

    // Format Weight
    asinData.formatted.itemWeight =
      "weight" in asinData.meta && asinData.meta.weight > 0
        ? addZeroes((asinData.meta.weight / 100).toFixed(2)) + " lbs"
        : "N/A";

    // Format BuyBox Prices
    asinData.formatted.usedBB = asinData.prices.buybox_used > 0 ? asinData.prices.buybox_used : 0;
    asinData.formatted.newBB = asinData.prices.buybox_new > 0 ? asinData.prices.buybox_new : 0;
    asinData.formatted.newPrice =
      "lowest_new" in asinData.prices && parseFloat(asinData.prices.lowest_new) > 0 ? asinData.prices.lowest_new : 0;
    asinData.formatted.amazonPrice =
      "amazon_price" in asinData.prices && parseFloat(asinData.prices.amazon_price) > 0
        ? asinData.prices.amazon_price
        : 0;

    // Check eScore
    asinData.formatted.escore =
      "e_score" in asinData.meta && asinData.meta.e_score >= 0 ? asinData.meta.e_score : "N/A";

    // Check for CANADA
    if ("other_markets" in asinData.meta && "ca" in asinData.meta.other_markets) {
      asinData.formatted.ca = {
        salesrank: -1,
      };
      asinData.formatted.ca.salesrank =
        asinData.meta.other_markets.ca.sales_rank > 0 && asinData.meta.other_markets.ca.sales_rank <= 999
          ? asinData.meta.other_markets.ca.sales_rank
          : asinData.meta.other_markets.ca.sales_rank > 999 && asinData.meta.other_markets.ca.sales_rank < 999999
          ? Math.round(asinData.meta.other_markets.ca.sales_rank / 1000) + "k"
          : asinData.meta.other_markets.ca.sales_rank > 999999
          ? (asinData.meta.other_markets.ca.sales_rank / 1000000).toFixed(1) + "M"
          : "N/A";

      // Format Trade-In Price
      asinData.formatted.ca.tradeIn =
        asinData.pricesCA.trade_in && parseFloat(asinData.pricesCA.trade_in) > 0 ? asinData.pricesCA.trade_in : 0;

      // Format Amazon Price
      asinData.formatted.ca.amazonPrice =
        "amazon_price" in asinData.pricesCA && parseFloat(asinData.pricesCA.amazon_price) > 0
          ? "$" + parseFloat(asinData.pricesCA.amazon_price).toFixed(2)
          : "N/A";

      // Format BuyBox Prices
      asinData.formatted.ca.usedBB = asinData.pricesCA.buybox_used > 0 ? asinData.pricesCA.buybox_used : 0;
      asinData.formatted.ca.newBB = asinData.pricesCA.buybox_new > 0 ? asinData.pricesCA.buybox_new : 0;
      asinData.formatted.ca.newPrice =
        "lowest_new" in asinData.pricesCA && parseFloat(asinData.pricesCA.lowest_new) > 0
          ? asinData.pricesCA.lowest_new
          : 0;
      asinData.formatted.ca.amazonPrice =
        "amazon_price" in asinData.pricesCA && parseFloat(asinData.pricesCA.amazon_price) > 0
          ? asinData.pricesCA.amazon_price
          : 0;

      // Check eScore
      asinData.formatted.ca.escore =
        asinData.meta.other_markets.ca.e_score >= 0 ? asinData.meta.other_markets.ca.e_score : "N/A";
    }

    return asinData;
  }
};

export const updateUserSettings = async (user_id, token, userSettings) => {
  try {
    const response = await fetch(apiBase + "user/settings", {
      method: "PUT",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        user_settings: JSON.stringify(userSettings),
      }),
    });
    const saveSettingsResponse = await response.json();
    if (response.status === 200) {
      return { status: 1, data: saveSettingsResponse };
    } else {
      return { status: 0, error: saveSettingsResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

// export const fetchSourceList = async (user_id, token) => {
//   try {
//     const response = await fetch(apiBase + "marketplace/list_sources", {
//       method: "POST",
//       body: new URLSearchParams({
//         user_id: user_id,
//         app_token: token,
//       }),
//     });
//     return response.status === 200 ? await response.json() : false;
//   } catch (error) {
//     return { status: 0, error: error };
//   }
// };

export const fetchListingProfiles = async (user_id, token) => {
  try {
    const response = await fetch(apiBase + "listing/get_profiles", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const addListingProfile = async (user_id, token, profile_name, profile_json) => {
  try {
    const response = await fetch(apiBase + "listing/profile", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        profile_name: profile_name,
        profile_json: JSON.stringify(profile_json),
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const updateCurrentShipment = async (shipmentName, displayName, user_id, token) => {
  try {
    const response = await fetch(apiBase + "shipments/update_current", {
      method: "POST",
      body: new URLSearchParams({
        shipment_name: shipmentName,
        display_name: displayName,
        user_id: user_id,
        app_token: token,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const logBookScouter = async (scanId, asin, bsPrice, user_id, token) => {
  try {
    const response = await fetch(apiBase + "isbn/log_bsclick", {
      method: "POST",
      body: new URLSearchParams({
        scan_id: scanId,
        asin: asin,
        bs_vendor: bsPrice.vendor,
        bs_price: bsPrice.price,
        user_id: user_id,
        app_token: token,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const ffCombinedResponse = async (user_data) => {
  let promisesFF = [],
    finalResponse = [];
  promisesFF.push(
    fetch(apiBase + "shipments/list_all", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_data.user_id,
        app_token: user_data.token,
      }),
    })
  );
  promisesFF.push(fetch(apiBase + "audio/list?team=" + user_data.team_id));
  promisesFF.push(
    fetch(apiBase + "listing/get_profiles", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_data.user_id,
        app_token: user_data.token,
      }),
    })
  );
  promisesFF.push(
    fetch(apiBase + "marketplace/list_sources", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_data.user_id,
        app_token: user_data.token,
      }),
    })
  );
  promisesFF.push(
    fetch(apiBase + "user/recent-scans", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_data.user_id,
        app_token: user_data.token,
      }),
    })
  );

  const ffResponses = await Promise.all(promisesFF.map((p) => p.catch((error) => null)));
  console.log(`G2`);
  for (let i = 0; i < ffResponses.length; i++) {
    const response = ffResponses[i];
    let responseFormatted = {};
    if (response.status === 200) {
      responseFormatted = await response.json();
      if (i === 0) responseFormatted.api = "shipments_list_all";
      else if (i === 1) responseFormatted.api = "audio_list";
      else if (i === 2) responseFormatted.api = "listing_profiles";
      else if (i === 3) responseFormatted.api = "sources_list";
      else responseFormatted.api = "scans_list";

      finalResponse.push(responseFormatted);
    } else {
      console.log(`Skipped`, response);
      continue;
    }
    //  response.status === 200 ? await response.json() : false;
  }
  console.log(`Going here`);
  return finalResponse;
};

// export const listShipmentsMap = async (user_id, token) => {
//   try {
//     const response = await fetch(apiBase + "shipments/list_all", {
//       method: "POST",
//       body: new URLSearchParams({
//         user_id: user_id,
//         app_token: token,
//       }),
//     });
//     return response.status === 200 ? await response.json() : false;
//   } catch (error) {
//     return { status: 0, error: error };
//   }
// };

export const fetchScansHistory = (user_id, token, callback) => {
  fetch(apiBase + "user/recent-scans", {
    method: "POST",
    body: new URLSearchParams({
      user_id: user_id,
      app_token: token,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchPreviousSources = (user_id, token, callback) => {
  fetch(apiBase + "sources/past", {
    method: "POST",
    body: new URLSearchParams({
      user_id: user_id,
      app_token: token,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const fetchMPScansHistory = (user_id, token, callback) => {
  fetch(apiBase + "user/recent-mp-scans", {
    method: "POST",
    body: new URLSearchParams({
      user_id: user_id,
      app_token: token,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const checkoutMPTrade = (user_id, token, shipmentData, callback) => {
  fetch(apiBase + "marketplace/checkout", {
    method: "POST",
    body: new URLSearchParams({
      user_id: user_id,
      app_token: token,
      shipment_data: JSON.stringify(shipmentData),
    }),
  })
    .then((resp) => resp.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
};

export const addNewShipment = async (moduleNames, displayName, user_id, token, isAutoOpen) => {
  try {
    const response = await fetch(apiBase + "shipments/", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        module_name: moduleNames,
        display_name: displayName,
        is_auto: isAutoOpen,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const closeShipment = async (shipmentId, user_id, token) => {
  try {
    const response = await fetch(apiBase + `shipments/close/${shipmentId}`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        shipment_id: shipmentId,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const updateActiveSourceApi = async (user_id, token, newSource, actionFor) => {
  try {
    const response = await fetch(apiBase + `marketplace/update_active`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        source_name: newSource,
        action_for: actionFor,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const getTitleSearchResults = async (querytext, user_id, token, searchContext) => {
  try {
    console.log(`Getting title`);
    const response = await fetch(apiBase + "title/search", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        query: querytext,
        search_context: searchContext === "Videos" ? "DVD" : searchContext.replace(" ", ""),
      }),
    });
    console.log(response);
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const isImportantClick = (targetElement) => {
  const targetClassName = targetElement.className.toString().trim();
  console.log(`Clicked: ${targetClassName}`, "custom-control-label");
  if (targetClassName.toString().indexOf("btn-danger") >= 0) return true;
  else if (
    targetClassName.toString().indexOf("custom-control-label") >= 0 ||
    targetClassName.toString().indexOf("custom-control-label custom-control-label") >= 0
  )
    return true;
  else if (
    targetClassName !== "" &&
    targetClassName !== "nav-link" &&
    targetClassName !== "form-control" &&
    targetClassName !== "form-control focus-visible" &&
    targetClassName !== "infoBoxes" &&
    targetClassName !== "infoValue bsinfo" &&
    targetClassName !== "btn btn-primary" &&
    targetClassName !== "infoHeading" &&
    targetClassName !== "infoValue bsinfo" &&
    targetClassName !== "infoValue" &&
    targetClassName !== "infoBoxes" &&
    targetClassName !== "infoBoxesNull" &&
    targetClassName !== "infoBoxes" &&
    targetClassName !== "custom-control-label" &&
    targetClassName !== "button-search  btn btn-primary" &&
    targetClassName !== "text_batchsettings form-control" &&
    targetClassName !== "inputboxBatchSettings form-control" &&
    targetClassName !== "mp-source form-control" &&
    targetClassName !== "fa fa-info-circle " &&
    targetClassName !== "btn btn-danger btn-sm" &&
    !targetClassName.toString().includes("css-")
  ) {
    return true;
  } else {
    return false;
  }
};

export const getASINWinner = async (
  listingProfile,
  asintoSearch,
  isLiveLoad,
  token,
  amzRestrictedMessage,
  currentProfile
) => {
  // console.log(`amzRestrictedMessage: ${amzRestrictedMessage}`);
  try {
    const isSBYBCondition = window.location.hostname === "app.myemptyshelves.com" ? 1 : 0;

    const response = await API.get(
      "isbn/" +
        asintoSearch +
        "/" +
        isLiveLoad +
        "?token=" +
        token +
        "&listing_profile=" +
        listingProfile +
        "&is_sbyb=" +
        isSBYBCondition +
        "&profile=" +
        currentProfile +
        (undefined !== amzRestrictedMessage ? `&rejected_message=` + amzRestrictedMessage : ``)
    );
    return response.data;

    // console.log(
    //   apiBase +
    //     "isbn/" +
    //     asintoSearch +
    //     "/" +
    //     isLiveLoad +
    //     "?token=" +
    //     token +
    //     "&listing_profile=" +
    //     listingProfile +
    //     "&is_sbyb=" +
    //     isSBYBCondition
    // );
    // console.log(apiResponse);
    // return apiResponse;
  } catch (error) {
    if (error.response) return error.response.data;
    else return { status: 0, error: error };
  }
};

// export const getSounds = async () => {
//   const response = await fetch(apiBase + "audio/list");
//   if (response.status === 200) {
//     const data = await response.json();
//     console.log(data);
//     return data;
//   } else return false;
// };

export const sendUpdateWinner = async (
  user_data,
  logID,
  asin,
  productGroup,
  searchText,
  idType,
  moduleWinner,
  subModuleWinner
) => {
  const response = await fetch(apiBase + "user/update_log", {
    method: "POST",
    body: new URLSearchParams({
      user_id: user_data.user_id,
      app_token: user_data.token,
      log_id: logID,
      module_winner: moduleWinner,
      submodule_winner: subModuleWinner,
      asin: asin,
      productGroup: productGroup,
      search_text: searchText,
      id_type: idType,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
